define("discourse/plugins/discourse-follow/discourse/components/follow-notification-preferences", ["exports", "@ember/component", "discourse-common/utils/decorators"], function (_exports, _component, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const preferences = ["notify_me_when_followed", "notify_followed_user_when_followed", "notify_me_when_followed_replies", "notify_me_when_followed_creates_topic", "allow_people_to_follow_me"];
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.observes)(...preferences.map(p => `user.${p}`)), (_obj = {
    _updatePreferences() {
      if (!this.user.custom_fields) {
        this.user.set("custom_fields", {});
      }
      preferences.forEach(p => {
        this.user.set(`custom_fields.${p}`, this.user[p]);
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "_updatePreferences", [_dec], Object.getOwnPropertyDescriptor(_obj, "_updatePreferences"), _obj)), _obj)));
});